* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

button,
a {
  cursor: pointer;
  text-decoration: none;
}

.image-preview-container {
  background-color: #000d;
  border-radius: 15px;
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 80%;
  height: 80%;
  z-index: 999;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    max-width: 100%;
    max-height: 100%;
  }
}

:root {
  --white: #fff;

  --blue-500: #272a6d;
  --blue-200: #4f5296;
  --blue-100: #7073af;
  --blue-50: #9fa1ce;

  --red-500: #ba2626;
  --red-700: #d91c1c;

  --green-500: #45af45;

  --blue-500: #4444aa;

  --gray-50: #efefef;
  --gray-100: #d8d8d8;
  --gray-200: #bfbfbf;
  --gray-300: #9f9f9f;
  --gray-400: #818181;
  --gray-500: #666666;
  --gray-600: #4a4a4a;
  --gray-700: #383838;
  --gray-800: #222222;
  --gray-900: #020202;
}

body {
  background-color: var(--gray-50);
}

@media screen and (max-width: 360px) {
  :root {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  :root {
    font-size: 15px;
  }
}

@media screen and (min-width: 1540px) {
  :root {
    font-size: 17px;
  }
}

@media screen and (min-width: 1700px) {
  :root {
    font-size: 18px;
  }
}

@font-face {
  font-family: "Inter";
  src: url(./assets/fonts/Inter-Regular.ttf);
}
